<template>
  <div class="mintSkeletonContainer">
    <Skeletor class="skeleton header"></Skeletor>
    <!--<Skeletor class="skeleton subtitle"></Skeletor>-->
    <Skeletor class="skeleton image"></Skeletor>
    <!--
    <Skeletor class="skeleton remaining"></Skeletor>
    <Skeletor class="skeleton dropdown"></Skeletor-->
  </div>
</template>
<script>
import { computed } from 'vue';

import 'vue-skeletor/dist/vue-skeletor.css';
import { Skeletor } from 'vue-skeletor';

export default {
  components: {
    Skeletor
  },

  props: {},

  setup(props) {}
};
</script>
<style scoped lang="scss">
.mintSkeletonContainer {
  width: 1000px;
  max-width: 80vw;
  display: flex;
  align-items: center;
  flex-direction: column;

  .skeleton {
    border-radius: 10px;
    width: 100%;
    margin-bottom: 20px;
    background-color:#8ef5c7;
  }

  .header {
    height: 60px;
    width: 60%;
    margin-bottom: 20px;
  }

  .subtitle {
    height: 40px;
    width: 50%;
    margin-bottom: 20px;
  }

  .image {
    height: 250px;
    width: 100%;
    margin-bottom: 30px;
  }

  .remaining {
    height: 40px;
    width: 50%;
  }

  .dropdown {
    height: 50px;
    width: 60%;
  }
}
</style>
