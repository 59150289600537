<template>
  <Ethereum :callback="initialize" />
  <div v-if="!correctNetwork && store.state.ethers.canSign" class="wrongNetworkWarning">You are connected to the wrong network, please connect to Rinkeby Network</div>
  <div v-if="!isConnected" class="mint" style="margin-top:100px;">
    <ConnectWallet />
  </div>
  <div v-else-if="isLoading" class="skeleton mint">
    <MintSkeleton />
  </div>
  <div v-else class="mint">
    <div class="heading">Mint</div>
    <!--
    <div class="imageContainer">
      <img src="" />
    </div>
-->
    <p class="text">{{ quantityRemaining }} Remaining</p>


    <div v-if="quantityRemaining > 0">
      <div v-if="!isLive">
        <div class="mintCountdown" >
          <p class="text mintCountdownText">Minting Beginning In:</p>
          <Countdown
            :startDate="store.state.mint.startDate"
            :endDate="store.state.mint.startDate + 1000000"
          ></Countdown>
        </div>
      </div>
      <div class="dropdownWrapper"  style="width:480px;">

        <div  style="background: #fff; padding:20px; margin-bottom:30px; text-align:center; border-radius:4px; box-shadow:3px 3px 15px #ccc;  ">
          Buy a token directly and skip the option system
          <br><br>
          <p>Price: {{ basicPrice }} ETH</p>
          <p>Quantity Selected: {{ mintQtySelection }}</p>
          <p>Total Cost: {{ totalCost }} ETH</p>
        </div>
        <div class="filled dropdown">
          <div style="background: #fff; padding:20px; margin-bottom:30px; border-radius:4px; box-shadow:3px 3px 15px #ccc; ">
            <div style="width:49%; display:inline-block">
              <p>Quantity</p>
            </div>
            <div style="width:49%; display:inline-block">
              <DropdownMenu
                class="dropdown"
                style="width:100%"
                :selection="mintQtySelection"
                :items="publicAllowances"
                @selected="handleSelection(index, $event)"
              />
            </div>
          </div>
          <Button style='width:100%' @click="purchaseToken()">Mint Item</Button>
        </div>
      </div>
    </div>

    <div v-else-if="quantityRemaining == 0">
      <p class="text">Sold Out!</p>
    </div>
  </div>
</template>
<script>
// Imports.
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { log } from '/src/utility';

// Component imports.
import DropdownMenu from '/src/components/ui/DropdownMenu.vue';
import Button from '/src/components/ui/Button.vue';
import Ethereum from '/src/components/common/Ethereum.vue';
import Countdown from '/src/components/ui/Countdown.vue';
import ConnectWallet from '/src/components/common/ConnectWallet.vue';
//import TabItem from '/src/components/ui/TabItem.vue';
import MintSkeleton from './components/MintSkeleton.vue';
import config from '/src/config'

export default {
  components: {
    DropdownMenu,
    Button,
    Ethereum,
    Countdown,
    ConnectWallet,
    //TabItem,
    MintSkeleton
  },

  setup() {
    const publicAllowances = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    //const termLengths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const store = useStore();

    const handleSelection = (index, selection) => {
      mintQtySelection.value = selection;
    };

    onMounted(() => {
      window.scroll(0, 0);
    });

    const isConnected = computed(() => {
      return store.state.ethers.canSign;
    });

    let now = ref(new Date());
    setInterval(() => {
      now.value = new Date();
    }, 1000);

    const isLive = computed(() => {
      //console.log("sstore date", store.state.option.config.startTime, now.value);
      return store.state.option.config.startTime < now.value;
    });

    const mintQtySelection = ref(1);
    const currentTab = ref(1);
    const isLoading = ref(true);

    const purchaseToken = async () => {
      await store.dispatch(
        'option/purchaseToken',
        {
          roundId: 0,
          amount: mintQtySelection.value
        },
        { root: true }
      );
      await store.dispatch('option/getRemainingItems', {}, {root: true});
      // await store.dispatch('mint/getShopItems', store.state.mint.shopAddress, {
      //   root: true
      // });
    };

    const initialize = async () => {
      // Retrieve the number of NFTs from the store.
      log.info('initialize');
      isLoading.value = true;

      if (store.state.ethers.canSign) {
        await store.dispatch('option/getConfig', {}, {root: true});
        await store.dispatch('option/getOwnedOptions', {}, {root: true});
        await store.dispatch('option/getRemainingItems', {}, {root: true});
        //
        // let item = store.state.mint.shop.pools[0].items[0];
        //
        // quantityRemaining.value = item.cap.toNumber() - item.minted.toNumber() - config.reservedMintCount;
      }

      // Poll remaining NFTs from the store.
      setInterval(() => {
        if (store.state.ethers.canSign) {
          // store.dispatch('mint/getMerkleData', { groupId: 1 }, { root: true });
          // allowances.value = store.state.mint.allowances;
        }
      }, 15000);


      isLoading.value = false;
      mintQtyOptions.value = [1,2,3,4,5];
    };

    watch(
      () => store.state.ethers.canSign,
      selection => {
        let canSign = store.state.ethers.canSign;
        if (canSign) {
          initialize();
        } else {
          // clearInterval(listener.value);
        }
      }
    );

    const mintQtyOptions = ref([]);
    const allowances = ref([]);

    const quantityRemaining = computed(() => {
      if (store.state.option) {
        // let item = store.state.mint.shop.pools[0].items[0];
        // return item.cap.toNumber() - item.minted.toNumber() - config.reservedMintCount;
        return store.state.option.remainingItems
      }
      return -1;
    });

    const termLengths = computed(() => {
      let cfg = store.state.option.config;
      let basicPrice = cfg.basicPrice;
      let discount = cfg.discountPerTermUnit;
      let terms = []
      for(let i = 1; basicPrice - (i * discount) > cfg.minPrice; i++){
        terms.push(i);
      }
      return terms;
    });

    const basicPrice = computed(() => {
      let cfg = store.state.option.config;
      return cfg.basicPrice / 10 **18
    });

    const totalCost = computed(() => {
      let cfg = store.state.option.config;
      let spend = mintQtySelection.value * cfg.basicPrice;
      return spend / 10 ** 18
    });

    const correctNetwork = computed(() => {
      return store.state.ethers.networkId == "0x4";
    });

    return {
      allowances,
      basicPrice,
      correctNetwork,
      isConnected,
      isLive,
      mintQtySelection,
      mintQtyOptions,
      handleSelection,
      initialize,

      purchaseToken,
      quantityRemaining,
      store,
      now,
      currentTab,

      isLoading,
      publicAllowances,
      termLengths,
      totalCost
    };
  }
};
</script>
<style scoped lang="scss">
.skeleton {
  padding: 60px 20px;
  padding-bottom: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mint {
  padding: 60px 20px;
  padding-bottom: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #7a7773;
  min-height:100vh;
  box-shadow: rgb(204 204 204) 3px 0px 5px inset;


  background: repeating-linear-gradient(
    -45deg,
    #fff,
    #fff 10px,
    #fafafa 10px,
    #fafafa 20px
  );

  .mintWrapper {
    margin-bottom: 20px;
    margin-top: 20px;

    .filled {
      border: 1px solid #7a7773;
      border-radius: 5px;
      padding: 15px;
    }
    .allocation {
      text-align: center;
      padding: 40px;
      font-size: 16px;
      line-height: 1em;
      font-weight: 700;
      border: 2px solid white;
      border-radius: 5px;
    }
  }

  .description {
    padding-top: 20px;
    opacity: 0.8;
    font-style: italic;
    font-weight: 600;
    text-align: center;
    max-width: 420px;
    line-height: 1.4em;
  }

  .tabs {
    display: flex;
    justify-content: center;
  }

  .dropdownWrapper {
    .dropdown {
      //display: grid;
      //grid-template-columns: auto 1fr;
      //margin-right: 10px;

      button {
        cursor: pointer;
        padding: 5px 20px;
        border-radius: 10px;
        height: 40px;
        -webkit-transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
          -webkit-transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
          -webkit-transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
          transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
          transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
          -webkit-transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        color: #7a7773;
        background-color: #aaf0d1;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        letter-spacing: 3px;
        text-decoration: none;
        text-transform: uppercase;
        border: none;
      }

      button:hover {
        box-shadow: 0 14px 40px 0 rgba(0, 0, 0, 0.2);
        -webkit-transform: translate(0px, -2px) scale(1.05);
        -ms-transform: translate(0px, -2px) scale(1.05);
        transform: translate(0px, -2px) scale(1.05);
        color: #7a7773;
      }

      button:active {
        box-shadow: none;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
      }

      .text {
        width: 100%;
        text-align: center;
      }
    }
  }

  .heading {
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 40px;
    line-height: 1em;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }

  .text {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 18px;
  }

  .imageContainer {
    max-width: 250px;
    max-height: 250px;
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
      border-radius: 5px;
      border: 2px solid white;
    }
  }

  .mintCountdownWhitelist {
    border:1px solid #777;
    border-radius:10px;
    padding:15px;
  }
  .mintCountdownWhitelistText {
    text-align: center;
  }

  .mintCountdown {
    border: 1px solid white;
    border-radius: 10px;
    padding: 15px;
  }
  .mintCountdownText {
    text-align: center;
  }
}

.wrongNetworkWarning {
  width:100%;
  text-align:center;
  padding:10px 0;
  background:#FFdd00;
}

@media (max-width: 700px) {
  .mint .heading {
    font-size: 12vw;
  }
}
</style>
