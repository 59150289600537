// Imports.
import { createRouter, createWebHistory } from 'vue-router';

// Page imports.
import Home from '../pages/home/Home.vue';
import Provenance from '../pages/provenance/Provenance.vue';
import Mint from '../pages/mint/Mint.vue';
import Option from '../pages/option/Option.vue';
import Exercise from '../pages/exercise/Exercise.vue';
import MyNFTs from '../pages/my-nfts/MyNFTs.vue';
import TermsPage from '../pages/terms/TermsPage.vue';
import NotFound from '../pages/not-found/NotFound.vue';
// Create routes.
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/mint',
    name: 'Mint',
    component: Mint
  },
  {
    path: '/option',
    name: 'Option',
    component: Option
  },
  {
    path: '/exercise',
    name: 'Exercise',
    component: Exercise
  },
  {
    path: '/my-nfts/:id',
    name: 'MyNFTs',
    component: MyNFTs
  },

  {
    path: '/provenance',
    name: 'Provenance',
    component: Provenance
  },

  {
    path: '/terms',
    name: 'Terms',
    component: TermsPage
  },

  { path: '/:pathMatch(.*)*', component: NotFound },
  { path: '/portal', component: NotFound }
];

// Set up the Vue router to operate in web history mode.
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
export default router;
