<template>
  <div class="aboutSection">
    <!--<div class="gradient"></div>-->
    <animated-component>
      <div class="about gradientBottom"  id="particle-container">
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <h2 style="color:#aaf0d1">Configuration</h2>
        <div class="split">
          <div class="subtext afade">
            <p>A token sale is configured with these properties:</p>
            <h3>basicPrice</h3>
            <p>The maximum price a user will pay for an item if they wish to purchase immediately</p><br>
            <h3>minimumPrice</h3>
            <p>The minimum price a user will pay for an option to mint if they wait the maximum term length</p><br>
            <h3>termUnit</h3>
            <p>The unit of time a user must wait for the price to be reduced by discountPerTermLimit</p><br>
            <h3>discountPerTermUnit</h3>
            <p>The amount the price of the option decays over a single termUnit</p><br>
            <h3>syncSupply</h3>
            <p>Whether to allow the number of item tokens to subceed the number of outstanding options</p><br>

            <p>The price a user pays for an option is calculated as basicPrice -
            (termLength * discountPerTermUnit) where termLength is the user's
            specified quantity of termUnits. The item's price will be the
            configured minimumPrice if this calculation results in a price lower
            than minimumPrice.</p>

          </div>
        </div>

        <div class="split" style="margin-left:4%;">
          <div class="subtext afade">
            <p>Example:</p>
            <p>Green Triangles Project wants to sell 1000 items.</p>

            <p>They setup the mintOption contract with a basic price of 1 eth</p>
            <pre>basicPrice = 1</pre><br>

            <p>
              They set the minimum price to .1, this means that the maximum
              discount a user can receive is .9 eth
            </p>
            <pre>minimumPrice = .1</pre><br>

            <p>They set the term unit to 1 day</p>
            <pre>termUnit = 86400 (seconds)</pre><br>

            <p>
              They set the discount per term unit to .1 eth, this means that
              every day the user is willing to wait to claim they will pay .1
              eth less for their purchase.
            </p>
            <pre>discountPerTermUnit = .1</pre><br>

            <p>
              With the above configuration a user will be able to purchase an
              option for .1 eth to mint an from the green triangles collection
              in 9 days. If the user doesn't want to wait that long, they could
              purchase an option for .6 eth, that they can exercise in 4 days.
              If the user doesn't want to wait at all, they can purchase the
              item directly for 1 eth, skipping the option system completely.
            </p>
            <br>
            <Button class="splashButton" @click="navigateTo('/option')">
              ok lets go
            </Button>
          </div>
        </div>
      </div>
    </animated-component>
    <!--
    <div class="slider">
      <ImageSlider />
    </div>
  -->
    <div class=""></div>
  </div>
</template>
<script>
// Imports.
//import ImageSlider from './ImageSlider.vue';
import router from '/src/router';
import Button from '/src/components/ui/Button.vue';

export default {
  components: {
    Button
  //  ImageSlider
  },

  data() {
    return {};
  },

  setup() {
    const navigateTo = route => {
      router.push(route);
    };
    return {
      navigateTo
    }

  }


};
</script>
<style scoped lang="scss">
p {
  margin:0 0 10px 0;
}
.aboutSection {
  background-color: #7a7773;
  color: #fff;
  display: grid;
  //grid-template-columns: 2fr 1fr;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  position: relative;
  //background: linear-gradient(to bottom right, rgba(0, 204, 104, .5) 30%, #7a7773);
  background: linear-gradient(to top left, rgba(0, 0, 0, .5) 10%, #0c0f15);
  // .gradient {
  //   //background: linear-gradient(#0c0f15 10%, rgba(0, 0, 0, 0));
  //   position: absolute;
  //   width: 100%;
  //   height: 120px;
  //   top: 0;
  // }


  .about {
    padding: 50px 40px 240px 40px;

    font-size: 28px;
    margin-left: auto;
    margin-right: auto;
    .split {
      width:48%;
      display:inline-block;
      vertical-align: top;
    }

    .header {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 15px;

      img {
        height: 35px;
      }

      h2 {
        font-size: 25px;

      }
    }
    .subtext {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }

  .images {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    min-width: 400px;
    gap: 10px;
    position: relative;

    .background {
      transform-style: preserve-3d;
      overflow: hidden;
      will-change: transform;
      position: absolute;
      height: 100vh;
      animation: slideshow 40s linear infinite;

      .image {
        width: 100vw;
        height: 100%;
        overflow: hidden;
        @keyframes slideshow {
          0% {
            top: 0;
          }
          100% {
            top: -200%;
          }
        }
      }
    }

    .imageColumn {
      display: flex;
      flex-direction: column;
      gap: 10px;
      transform-style: preserve-3d;
      overflow: hidden;
      will-change: transform;
      position: absolute;
      height: 100vh;
      animation: slideshow 40s linear infinite;

      img {
        width: 100vw;
        height: 200px;
        overflow: hidden;
        @keyframes slideshow {
          0% {
            top: 0;
          }
          100% {
            top: -100%;
          }
        }
        width: 200px;
        height: 200px;
        object-fit: contain;
      }
    }

    .imageColumnRight {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 100px;

      img {
        width: 200px;
        height: 200px;
        object-fit: contain;
      }
    }
  }
}

@media (max-width: 800px) {
  .aboutSection {
    grid-template-columns: 1fr;

    .slider {
      display: flex;
      justify-content: center;
    }

    .about {
      padding-bottom: 50px;
      text-align: center;

      .header {
        justify-content: center;
      }
    }
  }
}

@media (max-width: 800px) {
  .aboutSection .swiper {
    margin-left: -40vw;
  }
}

@media screen and (max-width: 479px) {
  .swiper-slide {
    max-width: 75%;
  }

  .aboutSection .swiper {
    margin-left: -52vw;
  }

  .aboutSection .swiper .picture img {
    width: auto;
    max-width: 70vw;
  }
}
</style>

<style scoped>
/* Fade animation */
.afade-enter-active,
.afade1-enter-active,
.afade2-enter-active {
  transition: all 1000ms ease;
}

.afade-enter-from,
.afade1-enter-from,
.afade2-enter-from {
  opacity: 0;
  transform: translateY(50px);
}

.afade1-enter-active {
  transition-delay: 400ms;
}

.afade2-enter-active {
  transition-delay: 800ms;
}
</style>
