'use strict';

// Specified state transitions per particular actions.
export default {
  updateConfig(state, config) {
    state.config = config;
  },

  updateOwnedOptions(state, ownedOptions) {
    state.ownedOptions = ownedOptions;
    state.ownedOptionsCount = ownedOptions.length;
  },

  updateOwnedItems(state, ownedItems) {
    state.ownedItems = ownedItems;
    state.ownedItemsCount = ownedItems.length;
  },

  updateExercisableOptions(state, exercisableOptions) {
    state.exercisableOptions = exercisableOptions;
    state.exercisableOptionsCount = exercisableOptions.length;
  },

  updateOptionAllowance(state, approved) {
    state.mintOptionApproved = approved;
  },

  updateRemainingItems(state, remainingItems) {
    state.remainingItems = remainingItems;
  },

  updateRemainingOptions(state, remainingOptions) {
    state.remainingOptions = remainingOptions;
  },
};
