<template>
  <Ethereum :callback="initialize" />
  <div v-if="!correctNetwork && store.state.ethers.canSign" class="wrongNetworkWarning">You are connected to the wrong network, please connect to Rinkeby Network</div>

  <div v-if="!isConnected" class="mint" style="margin-top:100px;">
    <ConnectWallet />
  </div>
  <div v-else-if="isLoading" class="skeleton mint">
    <MintSkeleton />
  </div>
  <div v-else class="mint">
    <div class="heading">Exercise an option</div>
    <!--
    <div class="imageContainer">
      <img src="" />
    </div>
  -->
    <div v-if="ownedOptions.length > 0">
      <div class="dropdownWrapper" >
        <div class="filled dropdown">
          <div  v-if="!mintOptionApproved"  style="width:480px; margin:10px auto 30px auto; background:#fff;padding:20px 40px;box-shadow:3px 3px 15px #ccc;  ">
            <p>You will need to approve the OpShop contract to spend your option tokens before you can exercise an option.</p>
            <br>
            <Button style='width:100%'  @click="approveMintOption()">Approve Contract</Button>
          </div>

          <table class="optionTable">
            <tr class="" >
              <td class="optionCell headerRow">ID</td>
              <td class="optionCell headerRow">Exercisable Timestamp</td>
              <td class="optionCell headerRow">Exercisable Date</td>
              <td class="optionCell headerRow">&nbsp;</td>
              <td class="optionCell headerRow right">Time Remaining</td>
              <td class="optionCell headerRow right">&nbsp;</td>
            </tr>
            <tr v-for="(option, index) in ownedOptions" :key="index">
              <td class="optionCell" style="width:60px;">{{option.id}}</td>
              <td class="optionCell">{{option.exer}}</td>
              <td class="optionCell">{{calculatedDate(option.exer)}}</td>

              <td class="optionCell">
                <a :href= "option.link" style="color:#298a5e" target="_blank">View on OpenSea</a>
              </td>
              <td class="optionCell right">{{option.rem}}</td>
              <td class="optionCell right" v-if="option.rem == 0 && !mintOptionApproved">
                <button class="disabledButton" disabled>EXERCISE</button>
              </td>
              <td class="optionCell right" v-if="option.rem != 0">
                <button class="disabledButton" disabled>EXERCISE</button>
              </td>
              <td class="optionCell right" v-if="option.rem == 0 && mintOptionApproved">
                <Button @click="exerciseOption(option.id)">Exercise</Button>
              </td>
            </tr>
          </table>
        </div>
      </div>

    <!--
      <div class="dropdownWrapper" >
        <div style="width:98%; padding:20px; margin-bottom:20px; text-align:center; border-radius:4px; border: 1px solid #bbb">
          Choose an option to redeem from the dropdown &nbsp;
        </div>
        <div class="filled dropdown">
          <div style="padding:20px; margin-bottom:20px; text-align:left; border-radius:4px; border: 1px solid #bbb">
            <p>Exercisable Option IDs</p><br>
            <DropdownMenu
              class="dropdown"
              style="width:100%"
              :selection="exerciseSelection"
              :items="ownedOptions"
              @selected="handleSelection(index, $event)"
            />
          </div>

            <Button v-if="!mintOptionApproved" style='width:100%'  @click="approveMintOption()">Approve Option</Button>

            <Button v-else style='width:100%' @click="exerciseOption()">Exercise</Button>

        </div>
      </div>
    -->
    </div>


    <div v-else>
      <p class="text">You don't have any options, go to the option page to puchase one</p>
    </div>
  </div>
</template>
<script>
// Imports.
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { log } from '/src/utility';

// Component imports.
//import DropdownMenu from '/src/components/ui/DropdownMenu.vue';
import Button from '/src/components/ui/Button.vue';
import Ethereum from '/src/components/common/Ethereum.vue';

import ConnectWallet from '/src/components/common/ConnectWallet.vue';
//import TabItem from '/src/components/ui/TabItem.vue';
import MintSkeleton from './components/MintSkeleton.vue';
import config from '/src/config'

export default {
  components: {
  //  DropdownMenu,
    Button,
    Ethereum,

    ConnectWallet,
    //TabItem,
    MintSkeleton
  },

  setup() {
    const publicAllowances = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const termLengths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const store = useStore();

    onMounted(() => {
      window.scroll(0, 0);
    });

    const isConnected = computed(() => {
      return store.state.ethers.canSign;
    });

    let now = ref(new Date());
    setInterval(() => {
      now.value = new Date();
    }, 1000);

    const isLive = computed(() => {
      //console.log("sstore date", store.state.option.config.startTime, now.value);
      return store.state.option.config.startTime < now.value;
    });


    const termLengthSelected = ref([]);
    const currentTab = ref(1);
    const isLoading = ref(true);

    const exerciseOption = async (tokenId) => {
      await store.dispatch(
        'option/exerciseOption',
        {
          tokenId: tokenId
        },
        { root: true }
      );

      await store.dispatch('option/getOwnedOptions', {}, {root: true});
    };

    const approveMintOption = async () => {
      await store.dispatch(
        'option/approveMintOption',
        {},
        { root: true }
      );
    };

    const initialize = async () => {
      // Retrieve the number of NFTs from the store.
      log.info('initialize');
      isLoading.value = true;

      if (store.state.ethers.canSign) {
        await store.dispatch('option/getConfig', {}, {root: true});
        await store.dispatch('option/getOwnedOptions', {}, {root: true});

        console.log("store cfg", store.state.option);
        //
        // let item = store.state.mint.shop.pools[0].items[0];
        //
        // quantityRemaining.value = item.cap.toNumber() - item.minted.toNumber() - config.reservedMintCount;
      }

      // Poll remaining NFTs from the store.
      setInterval(() => {
        if (store.state.ethers.canSign) {
          // store.dispatch('mint/getMerkleData', { groupId: 1 }, { root: true });
          // allowances.value = store.state.mint.allowances;
        }
      }, 15000);


      isLoading.value = false;
      mintQtyOptions.value = [1,2,3,4,5];

    };

    watch(
      () => store.state.ethers.canSign,
      selection => {
        let canSign = store.state.ethers.canSign;
        if (canSign) {
          initialize();
        } else {
          // clearInterval(listener.value);
        }
      }
    );

    const mintQtyOptions = ref([]);
    const allowances = ref([]);

    const quantityRemaining = computed(() => {
      if (store.state.mint.shop) {
        let item = store.state.mint.shop.pools[0].items[0];
        return item.cap.toNumber() - item.minted.toNumber() - config.reservedMintCount;
      }
      //return -1;
      return 100;
    });

    const timeFormat = (totalSeconds) => {
      let hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      let minutes = Math.floor(totalSeconds / 60);
      let seconds = Math.floor(totalSeconds % 60);
      let timestamp = ``;
      timestamp = (hours > 0) ? timestamp = `${hours}h` : ``;
      timestamp = (minutes > 0) ? timestamp = `${timestamp}${minutes}m` : timestamp;
      timestamp = (seconds > 0) ? timestamp = `${timestamp}${seconds}s` : timestamp;
      return timestamp;
    }

    const ownedOptions = computed(() => {
      let owned = [];
      if (store.state.option.ownedOptions) {
        for(let option of store.state.option.ownedOptions){
          let rem = (option.metadata.attributes[1].value > now.value / 1000)
            ? timeFormat(option.metadata.attributes[1].value - (now.value / 1000))
            : 0;
          let optionAddress = store.state.option.config.optionAddress;

          let link = `https://testnets.opensea.io/assets/${optionAddress}/${option.id}`;

          let item = {
            id: option.id,
            exer: option.metadata.attributes[1].value,
            rem: rem,
            link: link
          }
          owned.push(item);
        }
      }
      return owned;
    });

    const mintOptionApproved = computed(() => {
      return store.state.option.mintOptionApproved;
    });

    const correctNetwork = computed(() => {
      return store.state.ethers.networkId == "0x4";
    });

    const calculatedDate = (timestamp) => {
      var options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
      return new Date((timestamp) * 1000).toLocaleDateString("en-US", options);
    };

    return {
      allowances,
      calculatedDate,
      approveMintOption,
      correctNetwork,
      isConnected,
      isLive,
      termLengthSelected,
      mintQtyOptions,
      mintOptionApproved,
      initialize,

      exerciseOption,
      quantityRemaining,
      store,
      now,
      currentTab,
      ownedOptions,
      isLoading,
      publicAllowances,
      termLengths
    };
  }
};
</script>
<style scoped lang="scss">
.skeleton {
  padding: 60px 20px;
  padding-bottom: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mint {
  padding: 60px 20px;
  padding-bottom: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #7a7773;
  min-height:100vh;
  box-shadow: rgb(204 204 204) 3px 0px 5px inset;

  background: repeating-linear-gradient(
    -45deg,
    #fff,
    #fff 10px,
    #fafafa 10px,
    #fafafa 20px
  );

  .mintWrapper {
    margin-bottom: 20px;
    margin-top: 20px;

    .filled {
      border: 1px solid #777;
      border-radius: 5px;
      padding: 15px;
    }
    .allocation {
      text-align: center;
      padding: 40px;
      font-size: 16px;
      line-height: 1em;
      font-weight: 700;
      border: 2px solid white;
      border-radius: 5px;
    }
  }

  .description {
    padding-top: 20px;
    opacity: 0.8;
    font-style: italic;
    font-weight: 600;
    text-align: center;
    max-width: 420px;
    line-height: 1.4em;
  }

  .tabs {
    display: flex;
    justify-content: center;
  }

  .dropdownWrapper {
    .optionTable {
      border-collapse: collapse;
      box-shadow:3px 3px 15px #ccc;

      .optionCell {
        padding:15px 10px;
        border-bottom:#fff;
        min-width:130px;
        background: #fff;
      }
      .right {

        text-align: right;
      }
      .headerRow {
        background:#fafafa;
        font-weight:bold;

      }
    }
    .dropdown {

      grid-template-columns: auto 1fr;
      margin-right: 10px;

      button {
        cursor: pointer;
        padding: 5px 20px;
        border-radius: 2px;
        height: 25px;
        -webkit-transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
          -webkit-transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
          -webkit-transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
          transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
          transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
          -webkit-transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        color: #7a7773;
        background-color: #aaf0d1;
        font-size: 14px;
        font-weight: 600;
        text-align: center;

        //letter-spacing: 3px;
        text-decoration: none;
        text-transform: uppercase;
        border: none;
      }

      button:hover {
        box-shadow: 0 14px 40px 0 rgba(0, 0, 0, 0.2);
        -webkit-transform: translate(0px, -2px) scale(1.05);
        -ms-transform: translate(0px, -2px) scale(1.05);
        transform: translate(0px, -2px) scale(1.05);
        color: #7a7773;
      }

      button:active {
        box-shadow: none;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
      }
      button.disabledButton {
        background-color:#bbb;
        color:#999;
      }

      button.disabledButton:hover {
        box-shadow: none;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        cursor: not-allowed;
      }

      .text {
        width: 100%;
        text-align: center;
      }



    }
  }

  .heading {
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 40px;
    line-height: 1em;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }

  .text {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 18px;
  }

  .imageContainer {
    max-width: 250px;
    max-height: 250px;
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
      border-radius: 5px;
      border: 2px solid white;
    }
  }

  .mintCountdownWhitelist {
    border:1px solid #777;
    border-radius:10px;
    padding:15px;
  }
  .mintCountdownWhitelistText {
    text-align: center;
  }

  .mintCountdown {
    border: 1px solid white;
    border-radius: 10px;
    padding: 15px;
  }
  .mintCountdownText {
    text-align: center;
  }
}

.wrongNetworkWarning {
  width:100%;
  text-align:center;
  padding:10px 0;
  background:#FFdd00;
}

@media (max-width: 700px) {
  .mint .heading {
    font-size: 12vw;
  }
}
</style>
