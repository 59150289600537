'use strict';

import config from '/src/config';
// Import our module information.
import actions from './actions';
import mutations from './mutations';

// Prepare state.
const state = {
  config: {},
  ownedItems: [],
  ownedItemsCount: 0,
  ownedOptions: [],
  ownedOptionsCount: 0,
  exercisableOptions: [],
  exercisableOptionsCount: 0,
  mintOptionApproved: false,
  remainingOptions: 0,
  remainingItems: 0
};


// Return the module prepared for use.
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
