<template>
  <Ethereum :callback="initialize" />
  <div class="myClones">
    <div class="cloneGrid" v-if="loading">
      <CloneSkeleton v-for="(item, index) in 9" :key="index" />
    </div>

    <div class="cloneGrid" v-else-if="!loading && clones.length > 0">
      {{ clone }}
      <Clone
        v-for="(clone, index) in clones"
        :key="index"
        :clone="clone"
        @click="navigateToOpensea(clone)"
      />
    </div>
    <div class="none" v-else>

      <p class="text">You don't have any items :(</p>
    </div>
  </div>
</template>
<script>
import Clone from './components/Clone.vue';
import CloneSkeleton from './components/CloneSkeleton.vue';
import Ethereum from '/src/components/common/Ethereum.vue';
import { useStore } from 'vuex';
import { ethers } from 'ethers';
import { ref, watch, onMounted } from 'vue';
import router from '/src/router';
import config from '/src/config'

export default {
  components: {
    Clone,
    CloneSkeleton,
    Ethereum
  },

  setup() {
    const store = useStore();
    const loading = ref(true);
    const clones = ref([]);
    const address = router.currentRoute.value.params.id;

    const openSeaUrl = {
      '0x1': 'https://opensea.io/assets/',
      '0x4': 'https://testnets.opensea.io/assets/'
    };

    // if (store.state.mint.bucks.length === 0) loading.value = true;
    // Once the state variable changes, the watch is triggered.
    watch(
      () => store.state.mint.bucks,
      selection => {
        loading.value = false;
      }
    );

    const initialize = async () => {
      console.log("init");
      if (store.state.ethers.canSign) {
        await store.dispatch('option/getOwnedItems', {}, { root: true });
        clones.value = store.state.option.ownedItems;
      }

      loading.value = false;
    };

    const navigateToOpensea = clone => {
      const url = `${openSeaUrl[store.state.ethers.networkId]}${
        config.itemCollections[store.state.ethers.networkId]
      }/${clone.id}`;

      window.open(url, '_blank');
    };

    return { initialize, loading, clones, navigateToOpensea };
  }
};
</script>
<style scoped lang="scss">
.myClones {
  min-height: 100vh;//100%;

  background: repeating-linear-gradient(
    -45deg,
    #fff,
    #fff 10px,
    #fafafa 10px,
    #fafafa 20px
  );

  .text {
    font-size: 24;
    font-family: PressStart, sans-serif;
    height: 100%;
    text-align: center;
    margin-top: 70px;
  }

  .none {
    font-size: 24;
    font-family: PressStart, sans-serif;
    height: 100%;
    text-align: center;
    margin-top: 70px;


  }

  .cloneGrid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    padding: 30px;
    padding-bottom: 100px;
    justify-content: center;
    gap: 15px;
  }
}

@media (max-width: 1300px) {
  .myClones .cloneGrid {
    // grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 970px) {
  .myClones .cloneGrid {
    // grid-template-columns: 1fr 1fr;
  }
}
</style>
