<template>
  <div class="footer">
    <div class="logo" href="#splash" v-smooth-scroll>
      <img
        src="../../images/optmint_foot.png"
        alt="Footer Logo"
      />
    </div>
    <!-- <router-link to="/mint" class="link">
      Mint
    </router-link> -->

    <!-- <router-link to="/provenance" class="link">
      Provenance
    </router-link> -->

    <!-- <a href="#about" class="link" v-smooth-scroll @click="routeTo('/')"
      >About</a
    >
    <a href="#specs" class="link" v-smooth-scroll>Specs</a>
    <a href="#club" class="link" v-smooth-scroll>Club Benefits</a>
    <a href="#roadmap" class="link" v-smooth-scroll>Roadmap</a>
    <a href="#fairdrop" class="link" v-smooth-scroll>Fair Drop</a>
    <a href="#faq" class="link" v-smooth-scroll>FAQ</a> -->
    <div class="link" @click="openUrl(opShopAddress)">
      OpShop Contract (Rinkeby)
    </div>
    <div class="link" @click="openUrl(option721Address)">
      Option721 Contract (Rinkeby)
    </div>
    <div class="link" @click="openUrl(githubUrl)">
      Github Repo
    </div>
    <div class="link" @click="openUrl(discordUrl)">
      <DiscordIcon />
    </div>
    <div class="link" @click="openUrl(twitterUrl)">
      <TwitterIcon />
    </div>
  </div>
  <div class="terms">
    <p class="top" href="#top" v-smooth-scroll>Back to top</p>
    <p @click="routeTo('/terms')">Terms & Conditions</p>
  </div>
</template>
<script>
import config from '/src/config';
import DiscordIcon from '/src/components/icons/DiscordIcon.vue';
import TwitterIcon from '/src/components/icons/TwitterIcon.vue';
import { useStore } from 'vuex';

export default {
  components: {
    DiscordIcon,
    TwitterIcon
  },

  methods: {
    openUrl(url) {
      window.open(url, '_blank');
    },
    routeTo(path) {
      this.$router.push(path);
    }
  },
  setup(){
    const store = useStore();
    const netId = store.state.ethers.networkId;
    return {
      discordUrl: config.discordUrl,
      twitterUrl: config.twitterUrl,
      githubUrl: config.githubUrl,
      opShopAddress: `http://rinkeby.etherscan.io/address/${config.mintOptionAddress["0x4"]}`,
      option721Address: `http://rinkeby.etherscan.io/address/${config.optionAddress["0x4"]}`
    }
  }
};
</script>
<style scoped lang="scss">
.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;

  .logo {
    width: 240px;
    max-width: 100%;
    margin-right: auto;
    margin-bottom: 0px;
    cursor: pointer;
    transition: all 500ms ease;

    &:active {
      transform: scale(0.9);
    }
  }

  .link {
    display: flex;
    padding: 15px 20px;
    align-items: center;
    border-radius: 10px;
    transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    color: #8ef5c7;
    text-decoration: none;
    font-size: 16px;
    background-color: transparent;
    border: none;
    height: fit-content;
    white-space: nowrap;

    &:hover {
      cursor: pointer;
      background-color: hsla(0, 0%, 100%, 0.1);
    }

    &:active {
      background-color: hsla(0, 0%, 100%, 0.2);
    }
  }
}

.terms {
  display: flex;
  justify-content: flex-end;
  padding: 0px 20px 40px 20px;

  p {
    cursor: pointer;
    color: #8ef5c7;
    opacity: 0.7;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .top {
    text-decoration: none;
    margin-right: 20px;
  }
}

@media (max-width: 1200px) {
  .footer {
    flex-wrap: wrap;
    justify-content: center;
    .logo {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .terms {
    justify-content: center;
  }
}
</style>
