import itemABI from './ABIs/itemABI_xxxxxxx.json';
import mintShopABI from './ABIs/mintShopABI_xxxxxxx.json';

import optionABI from './ABIs/Option721.json';
import mintOptionABI from './ABIs/MintOption721.json';

const config = {
  infuraProjectId: '954e2f5992304238b4382298b5e9dea2',
  alchemyApiKey: {
    '0x4': '3UFuUZqwIV6g7mTvIZl30GYIFSkXqh_z',
    '0x5': 'SmRibxWnqII5gXgiuPDRiJT4gzA1vo7t'
  },

  networkPollingIntervals: {
    '0x1': 12000,
    '0x3': 12000,
    '0x4': 12000,
    '0x5': 12000,
    '0x2a': 12000
  },

  itemCollections: {
    '0x1': [''],
    '0x4': ['0x941De54a943D7E716e04e335123BfE21cF6AEf51'],
    '0x5': ['']
  },

  shopAddress: {
    '0x1': '',
    '0x4': '0x64008da688F44a25d64ae1fd99F9566aF21918B8',
    '0x5': ''
  },

  optionAddress: {
    '0x1': '0x00',
    '0x4': '0x89D7bAA5723d4F8Ef2B1A706DC9190b2dE724669'
  },

  mintOptionAddress: {
    '0x1': '0x00',
    '0x4': '0xcb089e94af7F5f563b608c54183735dC0122D63C'
  },

  itemABI,
  mintShopABI,
  optionABI,
  mintOptionABI,

  reservedMintCount: 444,
  mintPaddingPercent: 2,

  lang: {
    projectName: 'Project Name',
    projectNameLegal: 'Project-Legal-Name',
    whitelistDescriptions: [
      'Whitelist #0 description',
      'Whitelist #1 description',
      'Whitelist #2 description',
    ]
  },
  githubUrl: 'https://github.com/0xthrpw/MintOption721',
  discordUrl: 'https://discord.gg/some_discord',
  twitterUrl: 'https://twitter.com/some_twitter'
};

export default config;
