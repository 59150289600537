'use strict';

// Imports.
import { optionService } from '../../services';

export default {
  async getConfig({ dispatch, commit }, {}) {
    try {
      let parsedConfig = await optionService.getConfig();
      commit('updateConfig', parsedConfig);

    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async getOwnedOptions({ dispatch, commit }, {}) {
    try {
      let ownedOptions = await optionService.getOwnedOptions();
      commit('updateOwnedOptions', ownedOptions);

      let exercisableOptions = await optionService.getOwnedOptions(true);
      commit('updateExercisableOptions', exercisableOptions);

      let approved = await optionService.getOptionAllowance();
      commit('updateOptionAllowance', approved);

    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async getOwnedItems({ dispatch, commit }, {}) {
    try {
      let ownedItems = await optionService.getOwnedItems();
      commit('updateOwnedItems', ownedItems);

    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async getRemainingOptions({ dispatch, commit }, {}) {
    try {
      let remainingOptions = await optionService.getRemainingOptions();
      commit('updateRemainingOptions', remainingOptions);

    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async getRemainingItems({ dispatch, commit }, {}) {
    try {
      let remainingItems = await optionService.getRemainingItems();
      commit('updateRemainingItems', remainingItems);

    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async purchaseToken({ dispatch, commit },{ roundId, amount }) {
    try {
      await optionService.purchaseToken(
        roundId,
        amount,
        dispatch
      );
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async approveMintOption({ dispatch, commit },{ }) {
    try {
      let approved = await optionService.approveMintOption(dispatch);
      commit('updateOptionAllowance', approved);

    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async purchaseOption({ dispatch, commit }, { roundId, termLength, amount }) {
    try {
      await optionService.purchaseOption(
        roundId,
        termLength,
        amount,
        dispatch
      );
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async exerciseOption({ dispatch, commit },{ tokenId }) {
    try {
      await optionService.exerciseOption(
        tokenId,
        dispatch
      );
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },
};
