'use strict';

import config from '/src/config';
// Import our module information.
import actions from './actions';
import mutations from './mutations';

// Prepare state.
const state = {
  // startDate: new Date(1626897600 * 1000),
  shopAddress: config.shopAddress['0x1'],
  shop: null,
  bucks: [],
  ownsBuck: false,
  merkleUserIndex: '',
  merkleClaimedStatus: true,
  merkleProofs: [],
  allowances: [],
  presaleTimes: [],
  startDate: new Date(Date.UTC(2021, 0, 28, 21, 0))
};


// Return the module prepared for use.
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
