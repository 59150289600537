<template>
  <div class="splashArea">
    <div class="background">
      <div class="image"></div>
      <div class="image"></div>
      <div class="image"></div>
    </div>
    <div class="gradient"></div>
    <div class="aboutSection">
      <div class="container">
        <!-- <transition appear name="fade">
          <div>
            <img
              src="https://astrofrens-metadata.s3.amazonaws.com/assets/astro-frens-logo.svg"
              loading="lazy"
              alt="Astro Frens Logo"
              class="logo"
            />
          </div>
        </transition> -->
        <transition appear name="fade">
          <h2>Another Way of Minting</h2>
        </transition>
<!--
        <transition appear name="fade1">
          <div class="image">
            <img src="https://apes-r-us.s3.amazonaws.com/splash-logo.png" />
          </div>
        </transition>
-->
        <transition appear name="fade1">
          <p class="description">
            You've heard of English Auctions, Dutch Auctions, and Fixed Price Sales.  Are you ready to try a new way of minting NFTs?
          </p>
        </transition>

        <!-- Button -->
        <transition appear name="fade2">
          <div>
            <Button @click="scrollDown()">
              STFU
            </Button>
            &nbsp;
            <Button @click="navigateTo('/option')">
              LOL
            </Button>
          </div>
        </transition>
      </div>
      <!-- <div class="downCaret">
        <a href="#about" class="link down" v-smooth-scroll>
          <DownCaretIcon />
        </a>
      </div> -->
    </div>
  </div>
</template>
<script>
// Imports.
import router from '/src/router';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

// Component imports.
import Button from '/src/components/ui/Button.vue';
import Countdown from '/src/components/ui/Countdown.vue';

// Icon imports.
import DownCaretIcon from '/src/components/icons/DownCaretIcon.vue';
import ConnectWallet from '/src/components/common/ConnectWallet.vue';
export default {
  components: {
    // DownCaretIcon,
    Button
    // Countdown,
    // ConnectWallet
  },

  setup() {
    const store = useStore();
    const navigateTo = route => {
      router.push(route);
    };

    let now = ref(new Date());
    setInterval(() => {
      now.value = new Date();
    }, 1000);

    const isConnected = computed(() => {
      return store.state.ethers.canSign;
    });

    const isLive = computed(() => {
      return store.state.mint.startDate < now.value;
    });

    const scrollDown = () => {
      let height = document.documentElement.clientHeight;
      window.scroll(0, height);
    };

    const isSoldOut = ref(false);

    return {
      navigateTo,
      store,
      isConnected,
      isLive,
      isSoldOut,
      scrollDown
    };
  }
};
</script>
<style scoped lang="scss">
.splashArea {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background: repeating-linear-gradient(
    -45deg,
    #fff,
    #fff 10px,
    #fafafa 10px,
    #fafafa 20px
  );

  .background {
    transform-style: preserve-3d;
    overflow: hidden;
    will-change: transform;
    position: absolute;
    display: flex;
    height: 100vh;
    //animation: slideshow 40s linear infinite;

    .image {
      width: 100vw;
      height: 100%;
      //background-image: url('https://apes-r-us.s3.amazonaws.com/splash-image.png');
      background-position: 50% 50%;
      background-size: 100%;
      background-attachment: scroll;
      overflow: hidden;
    }
  }

  .gradient {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .navBar {
    padding: 20px;
    margin-right: 40px;

    .container {
      display: flex;
      justify-content: center;

      .link {
        display: flex;
        padding: 15px 20px;
        align-items: center;
        border-radius: 40px;
        transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        color: #7a7773;
        text-decoration: none;
        font-size: 16px;

        &:hover {
          cursor: pointer;
          background-color: hsla(0, 0%, 100%, 0.1);
        }

        &:active {
          background-color: hsla(0, 0%, 100%, 0.2);
        }
      }

      .connect {
        position: absolute;
        right: 20px;
        box-shadow: inset 0 0 0 1px #fff;
      }
    }
  }

  .aboutSection {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    .container {
      display: flex;
      width: 1800px;
      max-width: 90%;
      margin-right: auto;
      margin-left: auto;
      flex-direction: column;
      align-items: center;
      text-align: center;
      color: #7a7773;
      gap: 20px;

      .description {
        max-width: 750px;
        font-weight: bold;
      }

      .mintingCountdownWrapper {
        padding: 20px;
        border: 2px solid white;
        border-radius: 10px;
        .mintingTitle {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 20px;
        }
      }

      p {
        font-size: 16px;
      }

      .image {
        max-width: 450px;
        width: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .logo {
        width: 900px;
        margin-bottom: 40px;
        max-width: 100%;
        vertical-align: middle;
        display: inline-block;
        opacity: 1;
      }

      h2 {
        font-size: 30px;
      }

      .mintingWrapper {
        min-width: 200px;
        padding: 20px 30px;
        background-image: linear-gradient(180deg, #ffd915, #ffa41c);
        color: #000;
        font-size: 30px;
        font-weight: 500;
        text-align: center;
        text-decoration: none;
        display: flex;

        .space {
          margin-left: 10px;
        }
      }
    }

    .downCaret {
      position: absolute;
      color: #fff;
      animation: downCaret 1100ms infinite;
      cursor: pointer;
      transition: all 100ms ease;
      width: 100%;
      display: flex;
      justify-content: center;

      .link {
        color: white;
        width: 100%;
        text-align: center;
      }

      &:hover {
        transform: scale(1.05);
      }

      &:active {
        transform: scale(0.95);
      }
    }

    @keyframes downCaret {
      0% {
        bottom: 9vh;
      }
      50% {
        bottom: 7vh;
      }
      100% {
        bottom: 9vh;
      }
    }
  }
}

@media (max-width: 1050px) {
  .splashArea .aboutSection .container h2 {
    font-size: 4vw;
  }

  .splashArea .aboutSection .container .mintingWrapper {
    font-size: 4vw;
  }

  .splashArea .aboutSection .container .logo {
    display: none;
  }
}
</style>

<style scoped>
/* Fade animation */
.fade-enter-active,
.fade1-enter-active,
.fade2-enter-active {
  transition: all 1000ms ease;
}

.fade-enter-from,
.fade1-enter-from,
.fade2-enter-from {
  opacity: 0;
  transform: translateY(50px);
}

.fade1-enter-active {
  transition-delay: 400ms;
}

.fade2-enter-active {
  transition-delay: 800ms;
}
</style>
