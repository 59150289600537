<template>
  <div class="aboutSection">
    <!--<div class="gradient"></div>-->
    <animated-component>
      <div class="about gradientBottom" id="particle-container">
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <h2 style="color:#aaf0d1">Its kind of like a dutch auction, but priced just for you</h2>
        <div class="subtext afade">
          OpShop is an NFT minting contract that makes use of an 'Option' token.
          Instead of minting an item directly, a user can buy an option token at
          a time weighted discount and then redeem their option and mint their
          item once their term has been satisfied.  The discount a user receives
          is calculated by multiplying the number of 'termUnits' the user will
          wait, by the discount rate per unit of time (discountPerTermUnit).
          'termUnits' are measured in seconds and the contract can be configured
          to allow for arbitrary term lengths. The contract is also configured
          with a minimum price that acts as a sort of reserve price for the
          option tokens.
        </div>
<!--
        <div class="subtext afade">
          Traditional token sales are typically fixed price and do not allow for
          organic price discovery at mint. While limited price discovery can be
          allowed by a system like a Dutch Auction (allowing for a decaying price
          over time), participants are unable to set a personal time preference
          and could be priced out of the sale if strike price never drops to the
          desired entry level. Using a time based discount system allows price
          discovery to be completely decoupled from market consensus, it rewards
          long term participants and places a premium on short term speculation.
        </div>

        <div class="subtext afade1">
          We have developed a MintOption721 shop contract with a corresponding
          option token and have created a system for minting NFTs that allows
          users to purchase an option to mint an item at such a time-based
          discount. Users can purchase an option token with a capped per term
          discount, and specify the length of time they are willing to wait
          between paying for the option and exercising the option. Once the term
          is complete, users can exercise their option and redeem it to mint
          their item. Buyers with low time preference can choose to forgo the
          option method entirely and purchase their item immediately with no
          discount.
        </div>
-->
        <div class="subtext afade1">
          Option tokens are ERC721 tokens and store their metadata completely
          onchain.  Their properties, including exercise time, exercised status
          and owner can be viewed by calling the option contract tokenURI
          function at the option contract.  Option tokens can be bought or sold
          on the secondary market and can be exercised by the option holder once
          the term is satisfied.
        </div>
        <div class="subtext afade2">
          The option tokens themselves can be traded on the secondary market
          before their exercise date.  And just like any other ERC721 token, the
          buying and selling of option tokens can generate royalty revenue on
          NFT marketplaces like Opensea or Looksrare.
        </div>

        <div class="subtext afade3">
          <a class="techLink" href="https://github.com/0xthrpw/MintOption721" >Github Repo </a>
          <a class="techLink" :href="opShopAddress" >OpShop Contract (Rinkeby)</a>
          <a class="techLink" :href="option721Address" >Option721 Contract (Rinkeby)</a>
        </div>

        <Button class="splashButton" @click="scrollDown()">
          Technicals pls
        </Button>
        &nbsp;
        <Button class="splashButton" @click="navigateTo('/option')">
          Ok, let me try it
        </Button>
      </div>
    </animated-component>
    <!--
    <div class="slider">
      <ImageSlider />
    </div>
  -->
    <div class=""></div>
  </div>
</template>
<script>
// Imports.
//import ImageSlider from './ImageSlider.vue';
import config from '/src/config'
import router from '/src/router';
import Button from '/src/components/ui/Button.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  components: {
    Button
  //  ImageSlider
  },

  setup(){
    const store = useStore();

    const scrollDown = () => {
      let height = document.documentElement.clientHeight;
      window.scroll(0, 2 * height + 40);
    };

    const navigateTo = route => {
      router.push(route);
    };

    const opShopAddress = computed(() => {
      let netId = store.state.ethers.networkId
      return `http://rinkeby.etherscan.io/address/${config.mintOptionAddress[netId]}`;
    });

    const option721Address = computed(() => {
      let netId = store.state.ethers.networkId
      return `http://rinkeby.etherscan.io/address/${config.optionAddress[netId]}`;
    });

    return {
      navigateTo,
      opShopAddress,
      option721Address,
      scrollDown
    }
  }
};
</script>
<style scoped lang="scss">
.aboutSection {
  background-color: #7a7773;
  color: #fff;
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: linear-gradient(to top left, rgba(0, 0, 0, .5) 10%, #0c0f15);
  // .gradient {
  //   //background: linear-gradient(#0c0f15 10%, rgba(0, 0, 0, 0));
  //   position: absolute;
  //   width: 100%;
  //   height: 120px;
  //   top: 0;
  // }
  .techLink {
    display:inline-block;
    margin: 10px 20px 20px 0;
    color:#aaf0d1;
  }

  .splashButton {
    background-color:#aaf0d1;
    padding:5px 15px;
  }

  .about {
    padding: 50px 40px 240px 40px;

    font-size: 28px;
    margin-left: auto;
    margin-right: auto;

    .header {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 15px;

      img {
        height: 35px;
      }

      h1 {
        font-size: 25px;
      }
    }
    .subtext {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }

  .images {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    min-width: 400px;
    gap: 10px;
    position: relative;

    .background {
      transform-style: preserve-3d;
      overflow: hidden;
      will-change: transform;
      position: absolute;
      height: 100vh;
      animation: slideshow 40s linear infinite;

      .image {
        width: 100vw;
        height: 100%;
        overflow: hidden;
        @keyframes slideshow {
          0% {
            top: 0;
          }
          100% {
            top: -200%;
          }
        }
      }
    }

    .imageColumn {
      display: flex;
      flex-direction: column;
      gap: 10px;
      transform-style: preserve-3d;
      overflow: hidden;
      will-change: transform;
      position: absolute;
      height: 100vh;
      animation: slideshow 40s linear infinite;

      img {
        width: 100vw;
        height: 200px;
        overflow: hidden;
        @keyframes slideshow {
          0% {
            top: 0;
          }
          100% {
            top: -100%;
          }
        }
        width: 200px;
        height: 200px;
        object-fit: contain;
      }
    }

    .imageColumnRight {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 100px;

      img {
        width: 200px;
        height: 200px;
        object-fit: contain;
      }
    }
  }
}

@media (max-width: 800px) {
  .aboutSection {
    grid-template-columns: 1fr;

    .slider {
      display: flex;
      justify-content: center;
    }

    .about {
      padding-bottom: 50px;
      text-align: center;

      .header {
        justify-content: center;
      }
    }
  }
}

@media (max-width: 800px) {
  .aboutSection .swiper {
    margin-left: -40vw;
  }
}

@media screen and (max-width: 479px) {
  .swiper-slide {
    max-width: 75%;
  }

  .aboutSection .swiper {
    margin-left: -52vw;
  }

  .aboutSection .swiper .picture img {
    width: auto;
    max-width: 70vw;
  }
}
</style>

<style scoped>
/* Fade animation */
.afade-enter-active,
.afade1-enter-active,
.afade2-enter-active {
  transition: all 1000ms ease;
}

.afade-enter-from,
.afade1-enter-from,
.afade2-enter-from {
  opacity: 0;
  transform: translateY(50px);
}

.afade1-enter-active {
  transition-delay: 400ms;
}

.afade2-enter-active {
  transition-delay: 800ms;
}
</style>
