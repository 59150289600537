<template>
  <Ethereum :callback="initialize" />
  <SplashArea id="splash" />
  <AboutSection id="about" />
  <TechSection id="about" />
</template>
<script>
import Ethereum from '/src/components/common/Ethereum.vue';
import SplashArea from './components/SplashArea.vue';
import AboutSection from './components/AboutSection.vue';
import TechSection from './components/TechSection.vue';
import { computed, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';

export default {
  components: {
    AboutSection,
    Ethereum,
    SplashArea,
    TechSection
  },

  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => {
      return store.state.ethers.address;
    });

    const initialize = async () => {};

    return {
      store,
      isLoggedIn,
      initialize
    };
  }
};
</script>
<style scoped lang="scss">
.home {
  text-align: center;
  padding: 50px;
}
</style>
