'use strict';

// Imports.
import { ethersService } from './index';
import { ethers } from 'ethers';
import axios from 'axios';
import config from '/src/config'

const getConfig = async function() {

  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let address = await signer.getAddress();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);

  let mintOptionAddress = config.mintOptionAddress[networkId];
  let optionAddress = config.optionAddress[networkId];

  let mintOptionContract = new ethers.Contract(
    mintOptionAddress,
    config.mintOptionABI,
    provider
  );
  let cfg = await mintOptionContract.configs(0);

  let parsedConfig = {
    basicPrice: cfg.basicPrice,
    minPrice: cfg.minPrice,
    discountPerTermUnit: cfg.discountPerTermUnit,
    startTime: new Date(cfg.startTime.toString() * 1000),
    termUnit: cfg.termUnit.toString(),
    syncSupply: cfg.syncSupply.toString(),
    optionAddress: optionAddress
  };

  return parsedConfig;
};

const getRemainingOptions = async function() {
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let userAddress = await signer.getAddress();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);

  let optionAddress = config.optionAddress[networkId];

  let optionContract = new ethers.Contract(
    optionAddress,
    config.optionABI,
    provider
  );
  let cap = await optionContract.cap();
  let totalSupply = await optionContract.totalSupply();
  let remainingOptions = cap - totalSupply;
  return remainingOptions;
};

const getRemainingItems = async function() {
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let userAddress = await signer.getAddress();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);

  let itemAddress = config.itemCollections[networkId][0];

  let itemContract = new ethers.Contract(
    itemAddress,
    config.itemABI,
    provider
  );
  let cap = await itemContract.cap();
  let totalSupply = await itemContract.totalSupply();
  let remainingItems = cap - totalSupply;
  return remainingItems;
};

const getOptionAllowance = async function() {
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let userAddress = await signer.getAddress();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let optionAddress = config.optionAddress[networkId];
  let mintOptionAddress = config.mintOptionAddress[networkId];

  let optionContract = new ethers.Contract(
    optionAddress,
    config.optionABI,
    provider
  );

  let approved = await optionContract.isApprovedForAll(
    userAddress,
    mintOptionAddress
  );

  return approved;
};

const getOwnedItems = async function() {
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let userAddress = await signer.getAddress();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let itemAddress = config.itemCollections[networkId][0];

  let itemContract = new ethers.Contract(
    itemAddress,
    config.optionABI,
    provider
  );

  let ownershipData = {};
  let ownedItems = []
  let singleTransfers = await itemContract.queryFilter('Transfer');

  log.info(`Processing ${singleTransfers.length} transfer events ...`);
  for (let i = 0; i < singleTransfers.length; i++) {
    let singleTransfer = singleTransfers[i];
    let blockNumber = singleTransfer.blockNumber;
    let itemId = singleTransfer.args.tokenId;

    let to = singleTransfer.args.to;
    if (ownershipData[itemId.toString()]) {
      if (ownershipData[itemId.toString()].block <= blockNumber) {
        ownershipData[itemId.toString()] = {
          itemId: itemId,
          owner: to,
          block: blockNumber
        };
      }
    } else {
      ownershipData[itemId.toString()] = {
        itemId: itemId,
        owner: to,
        block: blockNumber
      };
    }
  }

  for (const itemId in ownershipData) {
    let itemOwnershipData = ownershipData[itemId];
    if (itemOwnershipData.owner === userAddress) {

      let tokenURI = await itemContract.tokenURI(itemId);

      let metadataResponse = await axios.get(tokenURI);
      let metadata = metadataResponse.data;

      if (metadata.image.substring(0, 7) == 'ipfs://') {
        metadata.image = `http://ipfs.io/ipfs/${metadata.image.substring(7)}`;
      }

    //   let data = tokenData.substring(29);
    //   let buff = new Buffer.from(data, 'base64');
    // console.log("buff", buff.toString('ascii'));
      //let metadata = JSON.parse(buff.toString('ascii'));
      // let imagedata = meta.image.substring(26);
      //
      // console.log("imagedata", imagedata.toString('ascii'));
      // let imgbuffer = new Buffer.from(imagedata, 'base64');
      //
      // console.log("ts", new Date(metadata.attributes[1].value.toString() * 1000), now);
      // if(filterExercisable){
      //   let exerciseTime = new Date(metadata.attributes[1].value.toString() * 1000)
      //   if(exerciseTime > now){
      //     break;
      //   }
      // }

      ownedItems.push({
        id: itemId,
        metadata: metadata,
        balance: 1,
        cap: ethers.BigNumber.from(10000)
      });
    }
  }

  return ownedItems;
};

const getOwnedOptions = async function(filterExercisable = null) {
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let userAddress = await signer.getAddress();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let optionAddress = config.optionAddress[networkId];
  let now = new Date();

  let optionContract = new ethers.Contract(
    optionAddress,
    config.optionABI,
    provider
  );

  let ownershipData = {};
  let ownedItems = []
  let singleTransfers = await optionContract.queryFilter('Transfer');

  log.info(`Processing ${singleTransfers.length} transfer events ...`);
  for (let i = 0; i < singleTransfers.length; i++) {
    let singleTransfer = singleTransfers[i];
    let blockNumber = singleTransfer.blockNumber;
    let itemId = singleTransfer.args.tokenId;

    let to = singleTransfer.args.to;
    if (ownershipData[itemId.toString()]) {
      if (ownershipData[itemId.toString()].block <= blockNumber) {
        ownershipData[itemId.toString()] = {
          itemId: itemId,
          owner: to,
          block: blockNumber
        };
      }
    } else {
      ownershipData[itemId.toString()] = {
        itemId: itemId,
        owner: to,
        block: blockNumber
      };
    }
  }

  for (const itemId in ownershipData) {
    let itemOwnershipData = ownershipData[itemId];
    if (itemOwnershipData.owner === userAddress) {

      let tokenData = await optionContract.tokenURI(itemId);
      let data = tokenData.substring(29);
      let buff = new Buffer.from(data, 'base64');

      let metadata = JSON.parse(buff.toString('ascii'));
      // let imagedata = meta.image.substring(26);
      //
      // console.log("imagedata", imagedata.toString('ascii'));
      // let imgbuffer = new Buffer.from(imagedata, 'base64');
      //
      if(filterExercisable){
        let exerciseTime = new Date(metadata.attributes[1].value.toString() * 1000)
        if(exerciseTime > now){
          break;
        }
      }

      ownedItems.push({
        id: itemId,
        metadata: metadata,
        balance: 1,
        cap: ethers.BigNumber.from(10000)
      });
    }
  }

  return ownedItems;
};

const purchaseToken = async function( roundId, amount, dispatch ) {
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let address = await signer.getAddress();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);

  let mintOptionAddress = config.mintOptionAddress[networkId];

  let mintOptionContract = new ethers.Contract(
    mintOptionAddress,
    config.mintOptionABI,
    signer
  );

  let optionConfig = await getConfig();
  let basicPrice = optionConfig.basicPrice;
  let totalSpend =  basicPrice.mul(amount);

  let purchaseTokenTx = await mintOptionContract.purchaseToken(
    roundId,
    amount,
    { value: totalSpend }
  );

  await dispatch(
    'alert/info',
    {
      message: 'Transaction Submitted',
      metadata: {
        transaction: purchaseTokenTx.hash
      },
      duration: 300000
    },
    { root: true }
  );
  await purchaseTokenTx.wait();

  await dispatch('alert/clear', '', { root: true });
  await dispatch(
    'alert/info',
    {
      message: 'Transaction Confirmed',
      duration: 10000
    },
    { root: true }
  );

};

const purchaseOption = async function( roundId, termLength, amount, dispatch ) {
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let address = await signer.getAddress();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);

  let mintOptionAddress = config.mintOptionAddress[networkId];

  let mintOptionContract = new ethers.Contract(
    mintOptionAddress,
    config.mintOptionABI,
    signer
  );

  let optionConfig = await getConfig();
  let basicPrice = optionConfig.basicPrice;
  let discountPerTermUnit = optionConfig.discountPerTermUnit;
  let price = basicPrice.sub(discountPerTermUnit.mul(termLength));
  let totalSpend =  price.mul(amount);

  let purchaseOption = await mintOptionContract.purchaseOption(
    roundId,
    termLength,
    amount,
    { value: totalSpend }
  );

  await dispatch(
    'alert/info',
    {
      message: 'Transaction Submitted',
      metadata: {
        transaction: purchaseOption.hash
      },
      duration: 300000
    },
    { root: true }
  );
  await purchaseOption.wait();

  await dispatch('alert/clear', '', { root: true });
  await dispatch(
    'alert/info',
    {
      message: 'Transaction Confirmed',
      duration: 10000
    },
    { root: true }
  );

};

const approveMintOption = async function( dispatch ) {
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let address = await signer.getAddress();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);

  let mintOptionAddress = config.mintOptionAddress[networkId];
  let optionAddress = config.optionAddress[networkId];

  let optionContract = new ethers.Contract(
    optionAddress,
    config.optionABI,
    signer
  );

  let state = true;
  let approvalTx = await optionContract.setApprovalForAll(
    mintOptionAddress,
    state
  );

  await dispatch(
    'alert/info',
    {
      message: 'Transaction Submitted',
      metadata: {
        transaction: approvalTx.hash
      },
      duration: 300000
    },
    { root: true }
  );
  await approvalTx.wait();

  await dispatch('alert/clear', '', { root: true });
  await dispatch(
    'alert/info',
    {
      message: 'Transaction Confirmed',
      duration: 10000
    },
    { root: true }
  );

  return state;
};

const exerciseOption = async function( tokenId, dispatch) {
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let address = await signer.getAddress();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);

  let mintOptionAddress = config.mintOptionAddress[networkId];
  let optionAddress = config.optionAddress[networkId];

  let mintOptionContract = new ethers.Contract(
    mintOptionAddress,
    config.mintOptionABI,
    signer
  );
  //
  // let exerciseOptionTx = await optionContract.exercisable(
  //   tokenId
  // );
  // let now = new Date();
  // let edate = new Date(exerciseOptionTx.toString() * 1000);
  // console.log("exer", tokenId, now, edate);
  let exerciseOptionTx = await mintOptionContract.exerciseOption(
    tokenId
  );

  await dispatch(
    'alert/info',
    {
      message: 'Transaction Submitted',
      metadata: {
        transaction: exerciseOptionTx.hash
      },
      duration: 300000
    },
    { root: true }
  );
  await exerciseOptionTx.wait();

  await dispatch('alert/clear', '', { root: true });
  await dispatch(
    'alert/info',
    {
      message: 'Transaction Confirmed',
      duration: 10000
    },
    { root: true }
  );

};

// Export the user service functions.
export const optionService = {
  approveMintOption,
  getConfig,
  getOwnedItems,
  getOwnedOptions,
  getOptionAllowance,
  getRemainingOptions,
  getRemainingItems,
  purchaseToken,
  purchaseOption,
  exerciseOption
};
